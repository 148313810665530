// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-commissions-js": () => import("./../../../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-film-js": () => import("./../../../src/pages/film.js" /* webpackChunkName: "component---src-pages-film-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-novel-js": () => import("./../../../src/pages/novel.js" /* webpackChunkName: "component---src-pages-novel-js" */),
  "component---src-pages-pearson-award-js": () => import("./../../../src/pages/pearson-award.js" /* webpackChunkName: "component---src-pages-pearson-award-js" */),
  "component---src-pages-playhouse-js": () => import("./../../../src/pages/playhouse.js" /* webpackChunkName: "component---src-pages-playhouse-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-pages-poetry-js": () => import("./../../../src/pages/poetry.js" /* webpackChunkName: "component---src-pages-poetry-js" */),
  "component---src-pages-prison-arts-foundation-js": () => import("./../../../src/pages/prison-arts-foundation.js" /* webpackChunkName: "component---src-pages-prison-arts-foundation-js" */),
  "component---src-pages-short-story-js": () => import("./../../../src/pages/short-story.js" /* webpackChunkName: "component---src-pages-short-story-js" */),
  "component---src-pages-spoken-word-js": () => import("./../../../src/pages/spoken-word.js" /* webpackChunkName: "component---src-pages-spoken-word-js" */),
  "component---src-templates-poem-template-js": () => import("./../../../src/templates/poem-template.js" /* webpackChunkName: "component---src-templates-poem-template-js" */)
}

